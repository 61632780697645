import React from 'react';
import { pdfjs } from 'react-pdf';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {
  KBarProvider,
} from "kbar";
import {actions} from "../../data/actions";
import {ResultItem} from "../shared/KbarResultItem";
import {CommandBar} from "../shared/KbarCommandBar";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  // @ts-ignore
  import.meta.url,
).toString();

type RootLayoutProps = {
  children: React.ReactNode,
};


const RootLayout = (props: RootLayoutProps): React.ReactElement => {
  const { children } = props;

  return (
    <KBarProvider
      options={{
        enableHistory: true,
      }}
      actions={actions}
    >
      <CommandBar />
      <>      {children}</>
    </KBarProvider>
  );

};

export default RootLayout;
