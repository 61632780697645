import {projects} from "./projects";
import {projectMapToArray} from "../utils/project";

export let actions = [
  {
    id: "home",
    name: "Home",
    shortcut: ["h"],
    keywords: "home",
    perform: () => (window.location.pathname = "/"),
  },
  {
    id: "blog",
    name: "Blog",
    shortcut: ["b"],
    keywords: "writing words",
    perform: () => (window.location.pathname = "blog"),
  },
  {
    id: "projects",
    name: "Projects",
    shortcut: ["p"],
    keywords: "project",
    perform: () => (window.location.pathname = "/projects"),
  },
]

function loadProjectsToActions(){
  const projects_data = projectMapToArray(projects) ;
  const projects_actions = projects_data.map((project) => ({
    id: project.id,
    name: project.name ? project.name : "",
    shortcut: [],
    keywords: project.name ? project.name: "",
    perform: () => (window.location.pathname = `/projects/${project.id}`),
  }))
  actions.push(...projects_actions)
}

loadProjectsToActions()
